import React, { useState } from "react";
import PropTypes from "prop-types";

const saveText = saved => {
  if (saved) return "unsave";
  if (!saved) return "save";
};

const toggleSavePainting = ({ saved, setSaved, painting_id, csrfToken }) => {
  const url = `/paintings/${painting_id}/${saveText(saved)}`;
  const headers = {
    "x-csrf-token": csrfToken
  };
  fetch(url, { method: "POST", headers })
    .then(res => res.json())
    .then(response => setSaved(response.saved))
    .catch(error => console.log("Error", error));
};

export default function SaveButton({ painting_id, initiallySaved, csrfToken }) {
  const [saved, setSaved] = useState(initiallySaved);
  const outlined = saved ? "" : "-o";
  const icon = "fa-bookmark";

  return (
    <button
      className="btn btn-primary"
      onClick={() =>
        toggleSavePainting({ saved, setSaved, painting_id, csrfToken })
      }
    >
      <i className={`fa ${icon}${outlined}`} aria-hidden="true"></i>
      {` ${saveText(saved)}`}
    </button>
  );
}
