import React from "react";

const VOTE_SUCCESS = "Thank you for your vote! You can return and vote again in 24 hours.";
const VOTE_FAILED = "You can only vote once per contest every 24 hours.";

// Get Contest Entries
export const getEntries = (url, setState) => {
    fetch(url)
        .then(res => res.json())
        .then(res =>
            setState(state => ({
                ...state,
                entries: res.contest_entries,
                paintings: res.paintings
            }))
        )
}

export const voteForEntry = entry => {
    fetch(`${entry.vote_url}?vote=1`)
        .then(res => res.json())
        .then(res => {
            if (res.vote_response === true)
                alert(VOTE_SUCCESS)

            if (res.vote_response === false)
                alert(VOTE_FAILED)
        })
}

const ContestEntryCard = ({ entry, showVote, user_signed_in }) => (
    <div className="image-element-class contest-entry">
        <img className="contest-entry-image" src={entry.painting_url} />
        <div className="contest-entry-caption">
            <div className="contest-entry-caption-inner">
                <a href={entry.url}>{entry.name}</a>
                <small>{entry.artist_name}</small>
            </div>
            {(user_signed_in === true) && (
                <button className="btn" onClick={() => voteForEntry(entry)}>Vote</button>
            )}
            {(user_signed_in === false) && (
                <a href="/users/sign_in" className="btn btn-primary pull-right">Sign In to Vote</a>
            )}
        </div>
    </div>
)

// Contest Entry
const ContestEntry = ({ entry, user_signed_in }) => (
    <ContestEntryCard entry={entry} user_signed_in={user_signed_in} />
);

export default ContestEntry;