import React from "react";
import Masonry from 'react-masonry-css';
import ContestEntry, { getEntries } from "./contest/ContestEntry";
import Winner from './contest/Winner';

// Contest Component
// /contests/:id
const Contest = props => {
  const { url, user_signed_in } = props;
  const initialState = { entries: [], winner: false };
  const [state, setState] = React.useState(initialState);
  const { entries, winner } = state;

  // Get Contest Entries On Load
  React.useEffect(() => {
    if (entries.length < 1) getEntries(url, setState);
  }, [entries]);

  // Contest Entries
  const contest_entries = entries.map(
    element => <ContestEntry entry={element} user_signed_in={user_signed_in} showVote={true} />
  );

  const masonry_options = {
    breakpointCols: {
      default: 3,
      1100: 3,
      700: 2,
      500: 1
    },
    className: "my-masonry-grid",
    columnClassName: "my-masonry-grid_column"
  }

  return (
    <div className="contest">
      {winner && <Winner />}
      <h2 className="text-center">Contest Entires</h2>
      <Masonry {...masonry_options}>
        {contest_entries}
      </Masonry>
    </div>
  );
}

export default Contest;
