import React, { useState } from "react";
import PropTypes from "prop-types";

const followText = followed => {
  if (followed) return "unfollow";
  if (!followed) return "follow";
};

const toggleSavePainting = ({
  followed,
  setFollowed,
  painting_id,
  csrfToken
}) => {
  const url = `/paintings/${painting_id}/${followText(followed)}`;
  const headers = {
    "x-csrf-token": csrfToken
  };
  fetch(url, { method: "POST", headers })
    .then(res => res.json())
    .then(response => setFollowed(response.followed))
    .catch(error => console.log("Error", error));
};

export default function FollowButton({
  painting_id,
  initiallyFollowed,
  csrfToken
}) {
  const [followed, setFollowed] = useState(initiallyFollowed);
  const outlined = followed ? "" : "-o";
  const icon = "fa-user";

  return (
    <button
      className="btn btn-primary"
      onClick={() =>
        toggleSavePainting({ followed, setFollowed, painting_id, csrfToken })
      }
    >
      <i className={`fa ${icon}${outlined}`} aria-hidden="true"></i>
      {` ${followText(followed)}`}
    </button>
  );
}
