import React, { useState } from "react";
import PropTypes from "prop-types";

const likeText = liked => {
  if (liked) return "unlike";
  if (!liked) return "like";
};

const likePainting = props => {
  const { liked, setLiked, setCount, painting_id, csrfToken } = props;
  const url = `/paintings/${painting_id}/${likeText(liked)}`;
  const headers = {
    "x-csrf-token": csrfToken
  };

  if (liked) return false;

  fetch(url, { method: "POST", headers })
    .then(res => res.json())
    .then(response => {
      setLiked(response.liked);
      setCount(response.liked_count);
    })
    .catch(error => console.log("Error", error));
};

export default function SaveButton(props) {
  const { painting_id, initiallyLiked, likeCount, csrfToken } = props;
  const [liked, setLiked] = useState(initiallyLiked);
  const [count, setCount] = useState(likeCount);
  const outlined = liked ? "" : "-o";
  const icon = "fa-heart";

  return (
    <button
      className="btn btn-primary"
      onClick={() =>
        likePainting({ liked, setLiked, setCount, painting_id, csrfToken })
      }
    >
      <i className={`fa ${icon}${outlined}`} aria-hidden="true"></i>
      {` ${count}`}
    </button>
  );
}
