import React, { PureComponent, useState } from "react";
import loadImage from "blueimp-load-image";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { EXIF } from "exif-js";

class ProfilePhoto extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      //   crop: { aspect: 1, width: 50, x: 0, y: 0 },
      crop: { width: 50, height: 50, x: 0, y: 0 },
      crop_complete: false
    };
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      var loadingImage = loadImage(
        e.target.files[0],
        (img, data) => {
          img.toBlob(a => {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
              this.setState({ src: reader.result })
            );
            reader.readAsDataURL(a);
          });
        },
        { maxWidth: 2000, canvas: true, meta: true, orientation: true }
      );
    }
    this.setState({ crop_complete: false });
  };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  base64ToArrayBuffer = base64 => {
    base64 = base64.replace(/^data\:([^\;]+)\;base64,/gim, "");
    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  async getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    console.log(ctx);

    const base64Image = canvas.toDataURL("image/jpeg");
    this.setState({ img: base64Image });

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        console.log(this.fileUrl);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div>
        <div>
          <center>
            <input
              ref="filefield"
              type="file"
              onChange={this.onSelectFile}
              id="profileimage_field_original"
              class="btn btn-block"
            />
          </center>
          <input
            type="hidden"
            id="profileimage_field"
            name={this.props.fieldName}
            value={this.state.img}
          />
          <label
            htmlFor="profileimage_field_original"
            className="btn btn-primary"
          >
            Upload a New Photo
          </label>
        </div>

        {!this.state.crop_complete && src && (
          <div>
            <ReactCrop
              src={src}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
            <a
              className="btn btn-block"
              onClick={() => {
                this.setState({ crop_complete: true });
              }}
            >
              <i className="fa fa-crop"></i> Finish Cropping
            </a>
          </div>
        )}

        {this.state.crop_complete && croppedImageUrl && (
          <img
            alt="Crop"
            className="imagefield"
            style={{ maxWidth: "100%" }}
            src={croppedImageUrl}
          />
        )}

        {/* {this.state.crop_complete && croppedImageUrl && (
          <div className="col-md-12 button-container-crop">
            <a
              className="button button-crop"
              onClick={() => {
                this.setState({ crop_complete: false });
              }}
            >
              <i className="fa fa-crop"></i> Recrop Image
            </a>
          </div>
        )} */}

        {this.state.crop_complete && croppedImageUrl && (
          <div className="col-md-12">
            <span id="avatar_message">Please click save to continue.</span>
          </div>
        )}

        {this.props.avatar != undefined && !croppedImageUrl && (
          <img
            src={this.props.avatar}
            className="avatar-form img-responsive imagefield"
            id="avatarImg"
          />
        )}
      </div>
    );
  }
}

export default ProfilePhoto;
