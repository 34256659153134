import React from "react";

const Winner = ({ winner }) => {
    return (
        <div className="winner">
            <h2 className="text-center">Winner</h2>
            Winner Painting
        </div>
    )
}

export default Winner;